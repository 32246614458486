

<template>
  <div class="px-4 py-3 list-item-component">
    <div class="vx-row md:px-4">
      <div class="vx-col md:w-3/4 w-1/2 items-center">
        <div class="flex items-center mb-1">
          <div class="post-tags mt-1 flex">
            <vs-chip transparent v-for="(tag, index) in data.tags" :key="index" :color="tag.color">
              {{ tag.name }}
            </vs-chip>
          </div>
        </div>
        <div class="flex items-center">
          <h4 class="post-title items-center mb-1">
            {{ data.post_title }}
          </h4>
        </div>
        <div class="flex items-center">
           <span transparent v-for="(kategori, index) in data.kategori" :key="index">
              <template v-if="data.kategori.length != 0 && index != 0">, </template>
              {{ kategori.name }}
            </span>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-1/2 flex items-center justify-end">
        <div class="flex items-center justify-end">
          <p class="truncate md:pl-6 pl-4">{{data.tanggal}}</p>
          <p class="truncate md:pl-6 pl-4">-</p>
          <p class="truncate md:pl-6 pl-4">{{data.tanggal_sampai}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      //data: this.$store.getters['event/getTask'](this.postId)
    }
  },
  computed: {
    postLabelText () {
      return (text) => {
        const tags = this.$store.state.event.postTags
        return tags.find((tag) => {
          return tag.slug === text
        }).name
      }
    },
    postLabelColor () {
      return (label) => {
        const tags = this.$store.state.event.postTags
        return tags.find((tag) => {
          return tag.slug === label
        }).color
      }
    }
  },
  methods: {
    persen(val) {
      return parseInt(val)
    },
    formatTotal(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
}
</script>
